import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

import { breakpoints, color, wrapper } from "../../utils/style"
import ButtonLink from "../shared/ButtonLink"
import Visa from "../../icons/svg/Visa"
import Mastercard from "../../icons/svg/Mastercard"
import Discover from "../../icons/svg/Discover"
import Paypal from "../../icons/svg/Paypal"
import Facebook from "../../icons/svg/FacebookPlain"
import Twitter from "../../icons/svg/TwitterPlain"
import Youtube from "../../icons/svg/YoutubePlain"
import Logo from "./LogoSvg"
import RespondioChat from "./RespondioChat";

const FooterBlock = styled("footer")`
  background: rgba(0, 0, 0, 0.2);
  padding: 50px 0;
`

const Wrapper = styled("div")`
  ${wrapper};
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  .footer-col {
    flex-basis: 20%;
    &-mobile {
      display: none;
      .social-buttons {
        margin-bottom: 40px;
      }
    }
  }
  .footer-col-wide {
    flex-basis: 48%;
  }
  .social-buttons {
    a {
      display: inline-block;
      cursor: pointer;
      &:hover,
      &:active,
      &:focus {
        svg {
          path {
            fill: ${color.white} !important;
          }
          filter: drop-shadow(1px 1px 6px ${color.lightBrown});
        }
      }
    }
  }
  @media (max-width: ${breakpoints.lg}) {
    .footer-col-wide {
      flex-basis: 50%;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
    .footer-col,
    .footer-col-wide {
      width: 100%;
      &-mobile {
        display: block;
      }
      &-desktop {
        display: none;
      }
    }
    .logo {
      display: block;
      max-width: 237px;
      margin: 0 auto;
    }
    .footer-col-wide {
      margin: 30px 0 40px;
    }
    .social-buttons {
      text-align: center;
    }
  }
`

const Copy = styled("div")`
  margin-top: 30px;
  font-size: 14px;
  line-height: 1.8;
  text-align: left;
  color: ${color.midGrey};

  a {
    text-decoration: underline;
  }
  a:hover,
  a:active,
  .active {
    text-decoration: none;
  }

  @media (max-width: ${breakpoints.md}) {
    text-align: center;
    margin: 20px 0;
  }
`

const FooterButtons = styled("div")`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  .col {
    flex-basis: 45%;
  }
  .col-right {
    flex-basis: 47%;
  }
  .line {
    padding: 3px 0 3px 15px;
  }
  .payment-span {
    display: inline-block;
    background: ${color.transparentWhite1};
    height: 50px;
    padding: 1px 0;
    border-radius: 5px;
    margin-right: 20px;
    vertical-align: middle;
    &:last-child {
      margin-right: 0;
    }
  }
  .top-line {
    margin-bottom: 16px;
  }
  .button {
    width: 100%;
    margin: 4px 0 20px;
    font-weight: 700;
    font-size: 1rem;
    span {
      text-transform: uppercase;
    }
  }
  .green-button {
    background-color: rgb(3, 164, 3);

    span {
      color: ${color.white};
    }

    &:hover {
      background-color: rgb(0, 197, 0);
    }
  }

  @media (max-width: ${breakpoints.lg}) {
    .col-right {
      flex-wrap: wrap;
      flex-basis: 50%;
    }
  }

  @media (max-width: ${breakpoints.sm}) {
    flex-direction: column-reverse;
    .col,
    .col-right {
      flex-basis: 100%;
    }
    .col-right {
      padding-top: 20px;
    }
    .line {
      display: flex;
      padding: 0;
      flex-flow: row wrap;
      justify-content: space-between;
    }
    .payment-span {
      width: calc(50% - 7px);
      text-align: center;
      margin-right: 10px;
    }
  }
`

export default () => (
  <FooterBlock>
    <Wrapper>
      <div className={"footer-col"}>
        <Logo to="/" text="th-ufabet.com" />
      </div>
      <FooterButtons className="footer-buttons footer-col-wide">
        <div className={"col col-right"}>
          <div className={"line top-line"}>
            <span className={"payment-span"}>
              <Visa />
            </span>
            <span className={"payment-span"}>
              <Mastercard />
            </span>
          </div>
          <div className={"line bottom-line"}>
            <span className={"payment-span"}>
              <Discover />
            </span>
            <span className={"payment-span"}>
              <Paypal />
            </span>
          </div>
        </div>

        <div className="col">
          <ButtonLink
            to={`tel:${process.env.PHONE_TO_CALL}`}
            external={true}
            ariaText="Call"
            className="button semi-transparent-button"
            iconColor={color.white}
            text={process.env.PHONE_TO_DISPLAY}
            iconName="call-answer"
            iconLeft={true}
            iconSize={26}
          />
          <ButtonLink
            to={process.env.LINE_ID_EXT}
            external={true}
            ariaText="Line"
            target="_blank"
            rel="noopener noreferrer"
            className="button green-button"
            text={process.env.LINE_ID_TEXT}
            iconName="line"
            iconColor={color.white}
            iconLeft={true}
            iconSize={36}
          />
        </div>
      </FooterButtons>
      <div className="footer-col footer-col-desktop">
        <div className={"social-buttons"}>
          <a
            href={process.env.FACEBOOK_LINK}
            rel="noreferrer"
            title="UFABET Facebook"
            target="_blank"
          >
            <Facebook />
          </a>
          <a
            href={process.env.YOUTUBE_LINK}
            rel="noreferrer"
            title="UFABET Youtube"
            target="_blank"
          >
            <Youtube />
          </a>
          <a
            href={process.env.TWITTER_LINK}
            rel="noreferrer"
            title="UFABET Twitter"
            target="_blank"
          >
            <Twitter />
          </a>
        </div>
        <Copy>
          <span>
            © 2017 - {new Date().getFullYear()} Goldenslot Thailand All Rights
            Reserved | goldenslot.gclub-casino.com{" "}
            <Link to="/sitemap/">Sitemap</Link>
          </span>
        </Copy>
      </div>
      <div className="footer-col footer-col-mobile">
        <Copy>
          <span>
            © 2017 - {new Date().getFullYear()} Goldenslot Thailand All Rights{" "}
            <br />
            Reserved | goldenslot.gclub-casino.com
          </span>
        </Copy>
        <div className="social-buttons">
          <a
            href={process.env.FACEBOOK_LINK}
            rel="noreferrer"
            title="UFABET Facebook"
            target="_blank"
          >
            <Facebook />
          </a>
          <a
            href={process.env.YOUTUBE_LINK}
            rel="noreferrer"
            title="UFABET Youtube"
            target="_blank"
          >
            <Youtube />
          </a>
          <a
            href={process.env.TWITTER_LINK}
            rel="noreferrer"
            title="UFABET Twitter"
            target="_blank"
          >
            <Twitter />
          </a>
        </div>
      </div>
    </Wrapper>
    <RespondioChat />
  </FooterBlock>
)
