import React, { useEffect } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import styled from '@emotion/styled';
import {
  color,
  breakpoints,
  NavUlStyles,
  NavStylesNew,
  wrapper
} from '../../utils/style';
import Logo from '../Header/LogoSvg';
import Hamburger from './Icons/Hamburger';
import MobileContactLinkRespondio from "./Icons/MobileContactLinkRespondio";
import MobileLineLink from './Icons/MobileLineLink';
import MobileCallLink from './Icons/MobileCallLink';
import TopSearch from './Icons/TopSearch';
import IcomoonIcon from '../shared/IcomoonIcon';
import HeaderContacts from '../Header/HeaderContacts';
import MobileCloseLink from '../Header/MobileCloseLink';
import SearchForm from '../Search/Form';
import Gift from '../../icons/svg/Gift';

const NavWrap = styled('div')`
  position: relative;
  z-index: 1;
  .logo {
    display: inline-block;
    margin: 0 2% 0 0;
    width: 255px;
    max-width: 54%;
    padding: 7px 0;
    position: relative;
    z-index: 1;
    svg {
      max-width: 100%;
    }
  }
  .active {
    & > a{
      color: ${color.gold};
      text-shadow: 0 0 5px #d60000, 0 1px 4px #e02020;
      svg{
        path{
          fill:${color.gold} !important;
        }
      }}
  }
  #ep91bbkxcge2_to {
    animation: ep91bbkxcge2_to__to 7000ms linear infinite normal forwards;
    animation-delay: 0ms;
  }

  #ep91bbkxcge2_ts {
    animation: ep91bbkxcge2_ts__ts 7000ms linear infinite normal forwards;
    animation-delay: 0ms;
  }

  #ep91bbkxcge3_tr {
    animation-delay: 0ms;
    animation: ep91bbkxcge3_tr__tr 7000ms linear infinite normal forwards
  }

  #ep91bbkxcge4_tr {
    animation-delay: 0ms;
    animation: ep91bbkxcge4_tr__tr 7000ms linear infinite normal forwards;
  }

  #ep91bbkxcge5_tr {
    animation-delay: 0ms;
    animation: ep91bbkxcge5_tr__tr 7000ms linear infinite normal forwards;
  }

  #ep91bbkxcge6_to {
    animation: ep91bbkxcge6_to__to 7000ms linear infinite normal forwards;
    animation-delay: 0ms;
  }

  #ep91bbkxcge6_ts {
    animation-delay: 0ms;
    animation: ep91bbkxcge6_ts__ts 7000ms linear infinite normal forwards;
  }

  @keyframes ep91bbkxcge2_to__to {
    0% {
      transform: translate(0px, 0px)
    }
    5.714286% {
      transform: translate(0px, 0px);
      animation-timing-function: cubic-bezier(0.420000, 0, 1, 1)
    }
    11.428571% {
      transform: translate(-1.050000px, -3px);
      animation-timing-function: cubic-bezier(0, 0, 0.580000, 1)
    }
    22.857143% {
      transform: translate(-1.050000px, -3px)
    }
    28.571429% {
      transform: translate(0px, 0px)
    }
    100% {
      transform: translate(0px, 0px)
    }
  }

  @keyframes ep91bbkxcge2_ts__ts {
    0% {
      transform: scale(1, 1)
    }
    5.714286% {
      transform: scale(1, 1)
    }
    11.428571% {
      transform: scale(1.100000, 1.100000)
    }
    22.857143% {
      transform: scale(1.100000, 1.100000)
    }
    28.571429% {
      transform: scale(1, 1)
    }
    100% {
      transform: scale(1, 1)
    }
  }

  @keyframes ep91bbkxcge3_tr__tr {
    0% {
      transform: translate(10.185889px, 8.789055px) rotate(20deg)
    }
    5.714286% {
      transform: translate(10.185889px, 8.789055px) rotate(20deg)
    }
    8.571429% {
      transform: translate(10.185889px, 8.789055px) rotate(20deg)
    }
    12.857143% {
      transform: translate(10.185889px, 8.789055px) rotate(8deg)
    }
    15.714286% {
      transform: translate(10.185889px, 8.789055px) rotate(6deg)
    }
    22.857143% {
      transform: translate(10.185889px, 8.789055px) rotate(8deg)
    }
    27.142857% {
      transform: translate(10.185889px, 8.789055px) rotate(20deg)
    }
    30% {
      transform: translate(10.185889px, 8.789055px) rotate(37deg)
    }
    34.285714% {
      transform: translate(10.185889px, 8.789055px) rotate(20deg)
    }
    100% {
      transform: translate(10.185889px, 8.789055px) rotate(20deg)
    }
  }

  @keyframes ep91bbkxcge4_tr__tr {
    0% {
      transform: translate(10.333333px, 8.789055px) rotate(-19.999985deg)
    }
    5.714286% {
      transform: translate(10.333333px, 8.789055px) rotate(-19.999985deg)
    }
    8.571429% {
      transform: translate(10.333333px, 8.789055px) rotate(-20deg)
    }
    12.857143% {
      transform: translate(10.333333px, 8.789055px) rotate(-8deg)
    }
    15.714286% {
      transform: translate(10.333333px, 8.789055px) rotate(-7deg)
    }
    22.857143% {
      transform: translate(10.333333px, 8.789055px) rotate(-8deg)
    }
    27.142857% {
      transform: translate(10.333333px, 8.789055px) rotate(-19.999985deg)
    }
    30% {
      transform: translate(10.333333px, 8.789055px) rotate(-37deg)
    }
    34.285714% {
      transform: translate(10.333333px, 8.789055px) rotate(-19.999985deg)
    }
    100% {
      transform: translate(10.333333px, 8.789055px) rotate(-19.999985deg)
    }
  }

  @keyframes ep91bbkxcge5_tr__tr {
    0% {
      transform: translate(10.185889px, 11.761277px) rotate(0deg)
    }
    8.571429% {
      transform: translate(10.185889px, 11.761277px) rotate(0deg)
    }
    12.857143% {
      transform: translate(10.185889px, 11.761277px) rotate(0deg)
    }
    14.285714% {
      transform: translate(10.185889px, 11.761277px) rotate(4deg)
    }
    15.714286% {
      transform: translate(10.185889px, 11.761277px) rotate(-4deg)
    }
    17.142857% {
      transform: translate(10.185889px, 11.761277px) rotate(4deg)
    }
    18.571429% {
      transform: translate(10.185889px, 11.761277px) rotate(-4deg)
    }
    20% {
      transform: translate(10.185889px, 11.761277px) rotate(0deg)
    }
    24.285714% {
      transform: translate(10.185889px, 11.761277px) rotate(0deg)
    }
    100% {
      transform: translate(10.185889px, 11.761277px) rotate(0deg)
    }
  }

  @keyframes ep91bbkxcge6_to__to {
    0% {
      transform: translate(0px, 0px)
    }
    5.714286% {
      transform: translate(0px, 0px)
    }
    11.428571% {
      transform: translate(-0.866667px, 0px)
    }
    22.857143% {
      transform: translate(-0.866667px, 0px)
    }
    28.571429% {
      transform: translate(0px, 0px)
    }
    100% {
      transform: translate(0px, 0px)
    }
  }

  @keyframes ep91bbkxcge6_ts__ts {
    0% {
      transform: scale(1, 1)
    }
    5.714286% {
      transform: scale(1, 1)
    }
    11.428571% {
      transform: scale(1.100000, 1.100000)
    }
    22.857143% {
      transform: scale(1.100000, 1.100000)
    }
    28.571429% {
      transform: scale(1, 1)
    }
    100% {
      transform: scale(1, 1)
    }
  }

  @media (max-width: ${breakpoints.lg}) {
    .logo {
      width: 45%;
    }
  }
  
  @media (max-width: ${breakpoints.md}) {
    margin-bottom:5px;
    position: static;
    .logo {
      padding: 10px;
      max-width: none;
      margin: 0;
    }
    &.search-open {
      position: relative;
      z-index: 100;
      width: 100%;
    }
  }

  @media (max-width: ${breakpoints.xs}) {
    position: static;
    .logo {
      position: absolute;
      left: 27.5%;
      top: 8px;
      padding: 0;
      margin: 0;
      max-width: 54%;
      margin-top: 3px;
    }
  }
  @media (max-width:${breakpoints.md}) and (orientation: landscape){
    &.invisible-header{
      display:none;
    }
  }
`;

const DesktopWrapper = styled('div')`
  ${wrapper}
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  .desktop-limiter {
    flex-grow: 1;
    padding-right: 45px;
    .header-promo-link {
      float: right;
      margin: 7px -20px 0 0;
      background: ${color.transparentWhite1};
      border: none;

      span {
        letter-spacing: 1px;
        line-height: 20px;
        margin-left: 10px;
        color: ${color.white};
      }
      &:hover,
      &:active,
      &:focus,
      &.active {
        background: ${color.transparentWhite2};
        span {
          text-shadow: 0 0 9px ${color.gold};
          color: ${color.white};
        }
      }
    }
  }
  @media (min-width: ${breakpoints.smMin}) {
    .header-promo-link {
      width: 171px;
      height: 48px;
      > svg {
        margin-top: -5px;
      }
    }
  }
  @media (max-width: ${breakpoints.lg}) {
    .desktop-limiter {
      flex-basis: 30%;
      padding-right: 30px;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    padding: 0;
    .desktop-limiter {
      flex-basis: 100%;
      text-align: center;
      padding-right: 70px;
      .header-promo-link {
        width: 48px;
        float: left;
        padding: 9px;
        margin-top: 15px;
        margin-left: 20px;
        span {
          display: none;
        }
      }
    }
    .mobile-fixed {
      background: ${color.black};
    }
    .header-contacts-menu.open {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      background: ${color.black};
      padding-top: 0;
      height: 75px;
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    .desktop-limiter {
      position: relative;
      height: 70px;
      .header-promo-link {
        margin: 0;
        position: absolute;
        top:13px;
        left:20px;
        height: 46px;
      }
    }
  }
`;

const BottomHeader = styled('div')`
  margin: 0;
  transition: all 0.35s ease-out;
  .bottom-nav {
    display: none;
  }

  @media (min-width: ${breakpoints.mdMin}) {
    &.fixed {
      margin: 0;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 10;
      background: ${color.lightBlack};
      height: 57px;
      .top-nav {
        margin: 3px auto;
      }
    }
  }
  @media (max-width: ${breakpoints.md}) {
    margin: 0;
    background: ${color.black};
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    border-top: 1px solid ${color.black};
    .bottom-nav {
      display: block;
      height: 48px;
      a,
      button {
        -webkit-appearance: none;
        border: 0;
        background: ${color.black};
        width: 25%;
        display: block;
        float: left;
        text-align: center;
        padding: 9px 5px 0;
        &:active,
        &:focus {
          outline: none;
          svg {
            path {
              fill: ${color.white} !important;
            }
            filter: drop-shadow(1px 1px 6px ${color.gold});
          }
        }
      }
      a {
        position: relative;
      }
      a::before {
        content: '';
        position: absolute;
        left: 0;
        top: 6px;
        height: 35px;
        width: 1px;
        background: ${color.transparentWhite1};
      }
    }
  }
`;

const NavUlWrap = styled('div')`
  ${NavUlStyles}
`;

const Nav = styled('ul')`
  ${NavStylesNew}
  
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px auto;
  
  .menu-item {
    display: flex;
    padding: 0 3rem;
    
    .top-menu-link {
      height: auto;
      padding: 0;
    }
    
    &:not(:first-of-type) {
      border-left: 2px solid rgba(255, 255, 255, .2);
    }
    
    &:nth-last-of-type(-n+2) {
      padding-right: 0;
    }
    
    &:first-of-type {
      padding-left: 0;
    }
  }
  
  @media (max-width: ${breakpoints.lg}) {
    .menu-item {
      padding: 0 3%;
      box-sizing: border-box;
      max-width: 25%;
    }
  }
  
  @media (max-width: ${breakpoints.md}) {
    .menu-item {
      padding: 0;
      max-width: none;
    }
  }
`;

const unBlockLayout = () => {
  const layout = document.getElementById('all-content');
  layout.classList.remove('inter-blocked');
};

const closeMobileMenu = () => {
  let elems = document.querySelectorAll('.open');
  [].forEach.call(elems, function (el) {
    el.classList.remove('open');
  });
  const navWrap = document.getElementById('nav-wrap');
  navWrap.classList.remove('search-open');
  unBlockLayout();
};

const getClosest = (elem, selector) => {
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      function (s) {
        let matches = (this.document || this.ownerDocument).querySelectorAll(s),
          i = matches.length;
        while (--i >= 0 && matches.item(i) !== this) { }
        return i > -1;
      };
  }

  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) return elem;
  }
  return null;
};

const closePopupHandler = () => {
  const layout = document.getElementById('all-content');
  if (layout) {
    layout.classList.remove('popup-blocked');
  }

  let activePopup = document.querySelector('.popup-open.popup-window');
  if (activePopup) {
    activePopup.classList.remove('popup-open');
  }
};

const handleScroll = () => {
  const elToFix = document.getElementById('toFix');
  const elToFixMobile = document.getElementById('toFixMobile');
  if (window.pageYOffset > elToFix.offsetTop) {
    elToFix.classList.add('fixed');
  } else {
    elToFix.classList.remove('fixed');
  }
  if (window.pageYOffset > elToFixMobile.offsetTop) {
    elToFixMobile.classList.add('mobile-fixed');
  } else {
    elToFixMobile.classList.remove('mobile-fixed');
  }
};

const MainMenu = () => {
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    /* Same for all popup windows */
    window.addEventListener('mouseup', function (event) {
      let activePopup = document.querySelector('.popup-open');
      if (
        event.target !== activePopup &&
        !getClosest(event.target, '.popup-open')
      ) {
        closePopupHandler();
      }
    });
    /* Same for all popup windows */
    window.addEventListener('touchend', function (event) {
      let activePopup = document.querySelector('.popup-open');
      if (
        event.target !== activePopup &&
        !getClosest(event.target, '.popup-open')
      ) {
        closePopupHandler();
      }
    });
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('scroll', handleScroll);
    };
  });
  return (
    <StaticQuery
      query={graphql`
        {
          allWordpressWpApiMenusMenusItems(filter: { slug: { eq: "main-menu" } }) {
            nodes {
              id
              name
              slug
              items {
                title
                classes
                attr
                object_slug
                path
                target
                url
              }
            }
          }
        }
      `}
      render={data => (
        <NavWrap className="nav-wrap" id="nav-wrap">
          <DesktopWrapper>
            <div className="desktop-limiter" id="toFixMobile">
              <Link
                to="/promotion/"
                activeClassName="active"
                id="header-promo-link-id"
                className="header-promo-link button"
                title="โปรโมชั่น"
              >
                <Gift />
                <span>โปรโมชั่น</span>
              </Link>
              <Logo to="/" text="th-ufabet.com" />
            </div>
            <HeaderContacts>
              <div className="service-wrapper header-search-wrapper">
                <MobileCloseLink handlerFunction={closeMobileMenu} />
                <SearchForm handlerFunction={closeMobileMenu} />
              </div>
            </HeaderContacts>

            <TopSearch />
          </DesktopWrapper>
          <BottomHeader id="toFix">
            <div className="bottom-nav">
              <Hamburger />
              <MobileLineLink />
              <MobileCallLink />
              <MobileContactLinkRespondio />
            </div>
            <NavUlWrap id="nav" className="top-nav">
              <Nav className="top-nav-ul">
                {data.allWordpressWpApiMenusMenusItems.nodes[0].items.map(
                  (item, index) => (
                    <li
                      id={index === 6 ? 'last' : index === 1 ? 'baccarat-menu' : index === 2 ? 'lighting' : ''}
                      className={
                        item.object_slug === 'home-page'
                          ? 'menu-item home ' + item.classes
                          : 'menu-item ' + item.classes
                      }
                      key={item.object_slug + Math.random()}
                    >
                      {item.target && (
                        <a className="top-menu-link" href={item.url} target={item.target}>
                          {item.attr && (
                            <IcomoonIcon
                              icon={item.attr}
                              color={color.gold}
                              size={20}
                            />
                          )}
                          <span>{item.title}</span>
                        </a>
                      )}
                      {(item.path || item.object_slug === 'home-page') && (
                        <Link
                          activeClassName="active"
                          className="top-menu-link"
                          to={'/' + item.path.replace('category/', '')}
                        >
                          {item.attr && (
                            <IcomoonIcon
                              icon="home-new"
                              color={color.gold}
                              size={20}
                            />
                          )}
                          <span>{item.title}</span>
                        </Link>
                      )}
                      {!item.target &&
                        !item.path &&
                        item.object_slug !== 'home-page' && (
                          <a className="top-menu-link" href="/">
                            {item.attr && (
                              <IcomoonIcon
                                icon={item.attr}
                                color={color.gold}
                                size={20}
                              />
                            )}
                            <span>{item.title}</span>
                          </a>
                        )}
                    </li>
                  )
                )}
                <li className="mobile-only">
                  <Link
                    to="/promotion/"
                    activeClassName="active"
                    id="header-promo-link-id-mobile"
                    className="header-promo-link button"
                    title="โปรโมชั่น"
                  >
                    <Gift />
                    <span>โปรโมชั่น</span>
                  </Link>
                  <a
                    className="button transparent-button register-button"
                    href="/register/?register_type=onsitegames"
                    aria-label="สมัครสมาชิก"
                  >
                    <span>สมัครสมาชิก</span>
                  </a>
                </li>
              </Nav>
            </NavUlWrap>
          </BottomHeader>
        </NavWrap>
      )}
    />
  );
};

export default MainMenu;
